import { graphql } from 'gatsby';
import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Layout } from '../../components/layout/layout';
import { textOptions } from '../../util/rich-text';
import {
  DesktopAuthorCard,
  MobileAuthorCard,
} from '../../components/cards/author-card';
import { PageHeading } from '../../components/layout/page-heading';
import FootprintsLayout from '../../components/layout/footprints-layout';

const CreativePiece = ({ data }) => {
  const poem = data.contentfulPoem;
  return (
    <Layout title={poem.title}>
      <FootprintsLayout>
        <div className="flex mt-6">
          <DesktopAuthorCard author={poem.author} />
          <main className="md:px-4 md:ml-8 flex flex-col items-center text-center">
            <PageHeading title={poem.title} />
            <MobileAuthorCard author={poem.author} />
            {poem.column1 && poem.column2 ? (
              <div className="mt-6 grid grid-cols-1 md:grid-cols-2 md:gap-x-8 text-center">
                <div>
                  {poem.column1 && renderRichText(poem.column1, textOptions)}
                </div>
                <div>
                  {poem.column2 && renderRichText(poem.column2, textOptions)}
                </div>
              </div>
            ) : (
              <div className="mt-6 grid grid-cols-1 text-center">
                <div>
                  {poem.column1 && renderRichText(poem.column1, textOptions)}
                </div>
              </div>
            )}
          </main>
        </div>
      </FootprintsLayout>
    </Layout>
  );
};

export default CreativePiece;

export const query = graphql`
  query ($slug: String!) {
    contentfulPoem(slug: { eq: $slug }) {
      title
      column1 {
        raw
      }
      column2 {
        raw
      }
      author {
        name
        description {
          description
          childMarkdownRemark {
            html
          }
        }
        displayPicture {
          fixed(width: 400) {
            width
            height
            src
            srcSet
          }
        }
      }
    }
  }
`;
